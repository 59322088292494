<template>
  <div>
    <!-- loading component -->
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    />

    <!-- page header component -->
    <page-header name="Edit Organization">
      <template #icon>
        <b-icon
          icon="sitemap"
          type="is-primary"
        />
      </template>

      <template #actions>
        <b-field>
          <b-switch
            v-model="isActive"
            type="is-info"
          >
            active
          </b-switch>
        </b-field>
      </template>
    </page-header>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-three-fifths">
            <validation-observer ref="organizationsForm">
              <!-- organizations form component -->
              <organization-form
                :users="users"
                :timezones="timezones"
              />
            </validation-observer>
          </div>

          <div class="column">
            <div class="card is-primary">
              <header class="card-header">
                <p class="card-header-title">
                  locations
                </p>
              </header>

              <div class="card-content">
                <!-- organizations locations form component -->
                <organizations-locations-form
                  :loading="loading"
                  :timezones="timezones"
                  :organization-locations="organizationLocations"
                  @save-location="createLocation"
                  @update-location="updateLocation"
                  @remove-location="deleteLocation"
                />
              </div>
            </div>
          </div>
        </div>

        <b-field>
          <b-button
            type="is-success"
            icon-left="save"
            @click="updateOrganization"
          >
            update organization
          </b-button>
        </b-field>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import {
  OrganizationForm,
  OrganizationsLocationsForm,
} from '@/components/Organizations';
import { PageHeader } from '@/components/Shared';

import OrganizationsMixin from '@/mixins/OrganizationsMixin';

export default {

  name: 'EditOrganization',

  components: {
    ValidationObserver,
    PageHeader,
    OrganizationForm,
    OrganizationsLocationsForm,
  },

  mixins: [OrganizationsMixin],

  async created() {
    await this.$store.dispatch(
      'Organizations/fetchOrganization',
      this.$route.params.organizationId,
    );
  },

  computed: {
    isActive: {
      get() {
        return this.$store.state.Organizations.organization.isActive;
      },

      set(value) {
        return this.$store.commit('Organizations/SET_ORGANIZATION_IS_ACTIVE', value);
      },
    },
  },

  methods: {
    async createLocation() {
      try {
        await this.$store.dispatch('Organizations/createOrganizationLocation', {
          location: this.location,
          organizationId: this.$route.params.organizationId,
        });
        this.$store.commit('Organizations/CLEAR_lOCATION');
        await this.$store.dispatch(
          'Organizations/fetchOrganization',
          this.$route.params.organizationId,
        );
        return this.$buefy.notification.open({
          message: 'Successfully added location',
          type: 'is-success',
        });
      } catch (error) {
        return this.$buefy.notification.open({
          message: error.message,
          type: 'is-warning',
        });
      }
    },

    async updateLocation(location) {
      try {
        const data = { ...location };
        delete data.createdAt;
        delete data.timezone;
        delete data.updatedAt;
        await this.$store.dispatch('Organizations/updateOrganizationLocation', {
          data,
          organizationId: this.$route.params.organizationId,
        });
        await this.$store.dispatch(
          'Organizations/fetchOrganization',
          this.$route.params.organizationId,
        );
        return this.$buefy.notification.open({
          message: 'Successfully updated the location',
          type: 'is-success',
        });
      } catch (error) {
        return this.$buefy.notification.open({
          message: error.message,
          type: 'is-warning',
        });
      }
    },

    deleteLocation(location) {
      this.$buefy.dialog.confirm({
        title: 'Deleting location',
        message: 'Are you sure you want to <b>delete</b> your location? This action cannot be undone.',
        confirmText: 'Delete Location',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Organizations/deleteOrganizationLocation', {
              locationId: location.id,
              organizationId: this.$route.params.organizationId,
            });
            await this.$store.dispatch(
              'Organizations/fetchOrganization',
              this.$route.params.organizationId,
            );
            return this.$buefy.notification.open({
              message: 'Successfully deleted location',
              type: 'is-success',
            });
          } catch (error) {
            return this.$buefy.notification.open({
              message: error.message,
              type: 'is-warning',
            });
          }
        },
      });
    },

    async updateOrganization() {
      try {
        const isValid = await this.$refs.organizationsForm.validate();
        if (!isValid) {
          // ABORT!!
        }
        delete this.organization.createdAt;
        delete this.organization.updatedAt;
        delete this.organization.locations;
        await this.$store.dispatch('Organizations/updateOrganization', {
          payload: this.organization,
          organizationId: this.$route.params.organizationId,
        });
        this.$buefy.notification.open({
          message: `Organization ${this.organization.name} has been updated successfully`,
          type: 'is-success',
        });
        return this.$router.push('/organizations');
      } catch (error) {
        return this.$buefy.notification.open({
          message: error.message,
          type: 'is-warning',
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
